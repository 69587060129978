import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import logoLG from "../assets/Group 8035.svg";
import ServicesLink from "./ServicesLink";
import { FORMA_DJR_DECK } from "../GlobalConstants";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#393939",
    width: "100%",
    padding: "5%",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "15%",
    },
    paddingRight: "1%",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "11%",
    },
  },
  text1: {
    fontFamily: FORMA_DJR_DECK,
    color: theme.palette.secondary.main,
    fontSize: 16,
  },
  logoContainer: {
    paddingBottom: "3%",
    [theme.breakpoints.up("md")]: {
      paddingTop: "10%",
    },
  },

  emailContainer: {
    paddingTop: "3%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "8%",
    },
  },
  text2: {
    fontFamily: FORMA_DJR_DECK,
    color: theme.palette.secondary.main,
    fontSize: 23,
    [theme.breakpoints.up("md")]: {
      fontSize: 23,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "3%",
    },
  },
  linkSM: {
    paddingBottom: "3%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  linkMD: {
    paddingLeft: "20%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));
const Services = (props) => {
  const classes = useStyles();

  return (
    <Grid xs={12} item container className={classes.root}>
      <Grid md={7} container item className={classes.linkSM}>
        <ServicesLink
          label="Services"
          link="/"
          action={props.focusOnServices}
          scroll="services"
        />
        <ServicesLink
          label="Contact"
          link=""
          action={props.focusOnContact}
          scroll="contacts"
        />
        <ServicesLink
          label={`Terms & Conditions`}
          link="terms-and-conditions"
        />
        <ServicesLink label="Privacy Policy" link="privacy-policy" />
      </Grid>
      <Grid md={5} container item justify="center">
        <Grid
          xs={12}
          item
          container
          justify="flex-start"
          className={classes.logoContainer}
        >
          <img alt="logo" src={logoLG} />
        </Grid>
        <Grid xs={12} item container justify="flex-start">
          <Typography className={classes.text1}>
            Bluebot Technologies Pvt Ltd.{" "}
          </Typography>
        </Grid>
        <Grid xs={12} item container justify="flex-start">
          {" "}
          <Typography className={classes.text1}>
            Address - C-338,2nd Floor,
          </Typography>
        </Grid>
        <Grid xs={12} item container justify="flex-start">
          <Typography className={classes.text1}>
            Gayatri Sadan, Sector 7 Dwarka, New Delhi
          </Typography>
        </Grid>
        <Grid
          xs={12}
          item
          container
          justify="flex-start"
          className={classes.emailContainer}
        >
          <Typography className={classes.text2}>ashish@adyll.com</Typography>
        </Grid>
      </Grid>
      <Grid md={7} container item className={classes.linkMD}>
        <ServicesLink
          label="Services"
          link="/"
          action={props.focusOnServices}
          scroll="services"
        />
        <ServicesLink
          label="Contact"
          link="/"
          action={props.focusOnContact}
          scroll="contacts"
        />
        <ServicesLink
          label={`Terms & Conditions`}
          link="terms-and-conditions"
        />
        <ServicesLink label="Privacy Policy" link="privacy-policy" />
      </Grid>
    </Grid>
  );
};

export default Services;
