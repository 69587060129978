import React from "react";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles, Toolbar, Button, Typography } from "@material-ui/core";
import LogoSM from "../assets/Group 8034.svg";
import LogoLG from "../assets/Group 8034@2x.svg";

import LeftMenuSm from "../assets/Group 7455.svg";
import ProfileSm from "../assets/Group 8018.svg";
import { FORMA_DJR_DECK } from "../GlobalConstants";
import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 60,
    flexGrow: 1,
    overflow: "hidden",

    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      boxShadow: "0 10px 10px -10px #000000",
      borderBottomWidth: 1,
      borderBottomStyle: "solid",
      borderBottomColor: "black",
    },
    [theme.breakpoints.up("md")]: {
      height: 80,
      paddingLeft: "10%",
      paddingRight: "10%",
      // boxShadow: "none",
      backgroundColor: theme.palette.secondary.main,
    },
  },
  grow: {
    flexGrow: 1,
  },
  ProfileSm: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  whiteBTN: {
    backgroundColor: theme.palette.secondary.main,
    marginRight: "2%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  whiteBTNTxt: {
    paddingRight: "2%",
    paddingLeft: "2%",
    fontFamily: FORMA_DJR_DECK,
    fontWeight: "bold",
    fontSize: 18,
  },
  toolBarWeb: {
    paddingTop: 10,
    alignContent: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  toolBarMobile: {
    justifyContent: "space-between",
    alignContent: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  loginBtn: {
    paddingLeft: 25,
    paddingRight: 25,
    fontFamily: FORMA_DJR_DECK,
    fontWeight: "bold",
    color: theme.palette.secondary.main,
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
  },
}));
const Header = React.forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <AppBar
      position="fixed"
      className={classes.root}
      color="transparent"
      ref={ref}
    >
      <Toolbar className={classes.toolBarWeb}>
        <a href="https://www.adyll.com" /* className={classes.homeLink} */>
          <img alt="Adyll" src={LogoLG} />
        </a>
        <div className={classes.grow} />

        <Button
          onClick={() => {
            props.history.push({ pathname: "/", state: "services" });
          }}
          className={classes.whiteBTN}
        >
          <Typography color="textSecondary" className={classes.whiteBTNTxt}>
            Services
          </Typography>
        </Button>
        <Button
          onClick={() => {
            props.history.push({ pathname: "/", state: "contacts" });
          }}
          className={classes.whiteBTN}
        >
          <Typography color="textSecondary" className={classes.whiteBTNTxt}>
            Contact
          </Typography>
        </Button>
        <Button
          style={{ marginLeft: "2%" }}
          className={classes.loginBtn}
          target="_blank"
          href="https://clickoz.offer18.com/m/login"
        >
          Login
        </Button>
      </Toolbar>
      <Toolbar className={classes.toolBarMobile}>
        <img alt="Adyll" src={LeftMenuSm} />
        <img alt="Adyll" src={LogoSM} />
        <a
          href="https://clickoz.offer18.com/m/login"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img alt="Adyll" src={ProfileSm} className={classes.ProfileSm} />
        </a>
      </Toolbar>
    </AppBar>
  );
});
export default withRouter(Header);
