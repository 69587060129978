import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import { rootReducer as reducers } from "./rootReducer";
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReduxThunk from "redux-thunk";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { MR_EAVESXL_MODERN, FORMA_DJR_DECK } from "./GlobalConstants";

const store = reducers && createStore(reducers, applyMiddleware(ReduxThunk));

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#A5A5A5",
      main: "#1D1D1F",
      dark: "#707070",
      error: "#f44336",
      success: "#4caf50",
    },
    secondary: {
      main: "#ffffff",
      light: "#E0E0E0",
      dark: "#000000",
      error: "#f44336",
      success: "#4caf50",
    },
    text: {
      primary: "#ffffff",
      secondary: "#000000",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      divider: "rgba(0, 0, 0, 0.12)",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: "#ffffff",
        borderRadius: 35,
        backgroundColor: "#1D1D1F",
        textTransform: "none",
        "&:hover": {
          backgroundColor: "#8c8c8c",
        },
      },
      text: {
        paddingRight: "2%",
        paddingLeft: "2%",
        fontFamily: FORMA_DJR_DECK,
        fontWeight: "bold",
        fontStyle: "normal",
      },
    },
    MuiInputBase: {
      root: {
        backgroundColor: "#ffffff",
        color: "#1D1D1F",
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: FORMA_DJR_DECK,
        "&$focused": {
          padding: "2%",
        },
      },
    },

    MuiFormLabel: {
      root: {
        color: "#707070",
        backgroundColor: "#ffffff",
        fontSize: 20,
        fontFamily: MR_EAVESXL_MODERN,

        "&$focused": {
          color: "#1D1D1F",
          borderWidth: 0,
        },
      },
    },
    MuiSelect: {
      root: {
        //color: "#1D1D1F",
        fontFamily: FORMA_DJR_DECK,
        fontWeight: "bold",
        textAlign: "left",
        paddingLeft: "3%",
      },

      icon: {
        color: "#1D1D1F",
      },
      selectMenu: {
        color: "#1D1D1F",
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: FORMA_DJR_DECK,
        color: "#1D1D1F",
        "&:hover": {
          backgroundColor: "#1D1D1F",
          color: "#ffffff",
        },
      },
    },
    MuiTypography: {
      body1: {
        lineHeight: 1,
      },
    },
  },

  /* typography: {
    useNextVariants: true,
  },
  overrides: {
    MuiTypography: {
      root: {
        color: "#435AA8",
      },
    },
    MuiMenuItem: {
      root: {
        color: "#435AA8",
        "&:hover": {
          backgroundColor: "#435AA8",
          color: "#ffff",
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: "#999999",
        backgroundColor: "#fff",
        fontSize: 20,
        fontWeight: "bold",

        "&$focused": {
          color: "#000",
          borderWidth: 1,
        },
      },
    },

   
    MuiInputBase: {
      root: {
        backgroundColor: "#fff",
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: "#F5F5F5",
      },
    },
  }, */
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
