import React, { createRef, useCallback, useEffect } from "react";
import scrollToComponent from "react-scroll-to-component";
import Introduction from "./Introduction";
import Purpose from "./Purpose";
import Expect from "./Expect";
import Strengths from "./Strengths";
import Join from "./Join";
import Clients from "./Clients";
import Form from "./Form";
//import Services from "./Services";

const Home = (props) => {
  const purposeRef = createRef();
  const servicesRef = createRef();
  const contactRef = createRef();
  const appHome = createRef();

  const focusOnPurpose = useCallback(() => {
    scrollToComponent(purposeRef.current, {
      offset: 0,
      align: "top",
      duration: 1000,
    });
  }, [purposeRef]);

  const focusOnServices = useCallback(() => {
    scrollToComponent(servicesRef.current, {
      offset: 0,
      align: "top",
      duration: 1000,
    });
  }, [servicesRef]);

  const focusOnContact = useCallback(() => {
    scrollToComponent(contactRef.current, {
      offset: 0,
      align: "top",
      duration: 1000,
    });
  }, [contactRef]);

  // const focusOnHome = () => {
  //   scrollToComponent(appHome.current, {
  //     offset: 0,
  //     align: "top",
  //     duration: 1000,
  //   });
  // };

  useEffect(() => {
    if (props.location.state) {
      switch (props.location.state) {
        case "services":
          focusOnServices();
          break;
        case "contacts":
          focusOnContact();
          break;

        default:
          break;
      }
    }
  }, [props.location.state, focusOnContact, focusOnServices]);
  return (
    <>
      <Introduction
        ref={appHome}
        focusOnPurpose={focusOnPurpose}
        focusOnContact={focusOnContact}
      />
      <Purpose ref={purposeRef} focusOnContact={focusOnContact} />
      <Expect ref={servicesRef} focusOnContact={focusOnContact} />
      <Strengths focusOnContact={focusOnContact} />
      <Join focusOnContact={focusOnContact} />
      <Clients />
      <Form ref={contactRef} />
    </>
  );
};

export default Home;
