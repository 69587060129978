import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyAb-CqyMlGKKFaA1Et-vscweYn0V0Uq0Tg",
  authDomain: "adyll-site-db01.firebaseapp.com",
  databaseURL: "https://adyll-site-db01.firebaseio.com",
  projectId: "adyll-site-db01",
  storageBucket: "adyll-site-db01.appspot.com",
  messagingSenderId: "408099276739",
  appId: "1:408099276739:web:852184476f60d96f109f76",
  measurementId: "G-45WCWVCTKS",
};

firebase.initializeApp(firebaseConfig);
export default firebase;
