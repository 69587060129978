import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import nounImg from "../assets/Group 7818.svg";
import groupSVG from "../assets/Group7881.svg";
import groupSVGMob from "../assets/Group 8019.svg";
import { MR_EAVESXL_MODERN, FORMA_DJR_DECK } from "../GlobalConstants";
import CustomButton2 from "../assets/CustomComponents/CustomButton2";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    overflow: "hidden",

    minHeight: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingTop: 127,
      paddingBottom: "20%",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: "13%",
      paddingBottom: "5%",
    },
  },
  text1Wrapper: {
    paddingTop: 100,
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
  },
  text1: {
    textAlign: "left",
    color: theme.palette.primary.light,
    fontSize: 16,
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
    },
    fontFamily: FORMA_DJR_DECK,
  },
  text2Wrapper: {
    paddingTop: 10,
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "70%",
    },
  },
  text2: {
    textAlign: "left",

    color: theme.palette.secondary.main,
    fontSize: 44,
    [theme.breakpoints.up("md")]: {
      fontSize: 55,
      lineHeight: 1,
    },

    fontFamily: FORMA_DJR_DECK,
  },
  text3Wrapper: {
    //paddingTop: 100,
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
      paddingBottom: "4%",
    },
  },
  text3: {
    textAlign: "left",
    color: theme.palette.secondary.main,
    fontSize: 44,
    fontWeight: "normal",
    [theme.breakpoints.up("md")]: {
      fontSize: 55,
      lineHeight: 1,
    },
    fontFamily: FORMA_DJR_DECK,
  },
  text3_1: {
    textAlign: "left",
    color: theme.palette.secondary.main,
    fontSize: 44,
    fontWeight: "normal",
    maxWidth: "70%",
    display: "inline",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    fontFamily: FORMA_DJR_DECK,
  },
  text3_2: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  text4Wrapper: {
    paddingTop: 20,
    paddingBottom: 20,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  text4: {
    color: theme.palette.secondary.main,
    fontSize: 25,
    fontFamily: MR_EAVESXL_MODERN,
  },
  btn2Wrapper: {
    paddingTop: 20,
    paddingBottom: 40,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("md")]: {
      paddingTop: 40,
    },
  },
  webNounSvg: {
    position: "absolute",
    [theme.breakpoints.up("md")]: {
      height: "75%",
      left: "5%",
    },
    [theme.breakpoints.up("lg")]: {
      left: "10%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  webNounSvgMob: {
    position: "absolute",
    right: "4%",
    height: "80%",
    bottom: "-1%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
const Introduction = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <Grid xs={12} item container className={classes.root} ref={ref}>
      <img src={groupSVG} className={classes.webNounSvg} alt="Noun" />
      <img src={groupSVGMob} className={classes.webNounSvgMob} alt="Noun" />
      <Grid xs={12} item container className={classes.text1Wrapper}>
        <Typography className={classes.text1}>SINCE 2017</Typography>
      </Grid>
      <Grid xs={12} item container className={classes.text2Wrapper}>
        <Typography className={classes.text2}>At the core, we are a</Typography>
      </Grid>
      <Grid xs={12} item container className={classes.text3Wrapper}>
        <Typography className={classes.text3}>
          <Typography className={classes.text3_1}>
            Performance Marketing
          </Typography>
          <b className={classes.text3_2}>Performance Marketing</b> {` Firm`}
        </Typography>
      </Grid>
      <Grid
        xs={12}
        item
        container
        justify="center"
        className={classes.text4Wrapper}
      >
        <Typography className={classes.text4}>
          ROI is our top most priority
        </Typography>
      </Grid>
      <CustomButton2 focusOnContact={props.focusOnContact} />
      <Grid
        xs={12}
        item
        container
        justify="center"
        className={classes.btn2Wrapper}
      >
        <img
          src={nounImg}
          alt="Noun"
          onClick={props.focusOnPurpose}
          style={{ cursor: "pointer", transform: "rotate(90deg)" }}
        />
      </Grid>
    </Grid>
  );
});

export default Introduction;
