import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import CustomButton1 from "../assets/CustomComponents/CustomButton1";
import { FORMA_DJR_DECK } from "../GlobalConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    padding: "5%",
    paddingTop: "10%",
    paddingRight: "1%",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "12%",
      paddingBottom: "3%",
    },
  },
  text1Container: {
    justifyItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      // paddingLeft: "5%",
      //justifyItems: "center",
    },
  },
  text1_1: {
    color: theme.palette.primary.main,
    fontSize: 30,
    fontFamily: FORMA_DJR_DECK,
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: 50,
      maxWidth: "60%",
    },
  },
  itemsContainer: {
    paddingTop: "10%",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "3%",
      paddingTop: 0,
      paddingBottom: 40,
      maxWidth: "100%",
    },
  },
  item1: {
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      marginBottom: 40,
    },
  },
  item1_1: {
    fontFamily: FORMA_DJR_DECK,
    fontSize: 56,
    textAlign: "left",
    fontWeight: "bold",

    [theme.breakpoints.down("sm")]: {
      fontSize: 56,
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 70,
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 80,
    },
  },
  item2: {
    [theme.breakpoints.up("md")]: {
      paddingTop: 17,
    },
  },
  item2_1: {
    maxWidth: "60%",
    fontFamily: FORMA_DJR_DECK,
    fontSize: 20,
    textAlign: "left",

    [theme.breakpoints.up("sm")]: {
      fontSize: 20,
      marginTop: 17,
      maxWidth: "40%",
      textAlign: "left",
      marginLeft: "2%",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 30,
      marginTop: 7,
      maxWidth: "60%",
      marginLeft: "2%",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: 30,
      marginTop: 7,
      maxWidth: "38%",
    },
  },
  WebBtns: {
    marginTop: "4%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  MobBtns: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));
const Strengths = (props) => {
  const classes = useStyles();

  return (
    <Grid xs={12} item container className={classes.root}>
      <Grid xs={12} md={3} item container className={classes.text1Container}>
        <Typography className={classes.text1_1} align="left">
          Our Strengths so far...
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={9}
        item
        spacing={2}
        container
        className={classes.itemsContainer}
      >
        <Grid xs={6} item container className={classes.item1}>
          <Typography className={classes.item1_1}>1.8K</Typography>
          <Typography className={classes.item2_1}>
            Successful Campaigns
          </Typography>
        </Grid>
        <Grid xs={6} item container>
          <Typography className={classes.item1_1}>400</Typography>
          <Typography
            className={classes.item2_1}
          >{`Happy Clients `}</Typography>
        </Grid>
        <Grid xs={6} item container>
          <Typography className={classes.item1_1}>1.2K</Typography>
          <Typography className={classes.item2_1}>Active Publishers</Typography>
        </Grid>
        <Grid xs={6} item container>
          <Typography className={classes.item1_1}>100K</Typography>
          <Typography className={classes.item2_1}>
            Acquisitions Per Month
          </Typography>
        </Grid>
        <Grid xs={12} item container className={classes.WebBtns}>
          <CustomButton1 focusOnContact={props.focusOnContact} />
        </Grid>
      </Grid>
      <Grid xs={12} item container className={classes.MobBtns}>
        <CustomButton1 focusOnContact={props.focusOnContact} />
      </Grid>
    </Grid>
  );
};

export default Strengths;
