import React, { useState } from "react";
import {
  makeStyles,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@material-ui/core";
import Error from "./Error";
import Firestore from "./Firestore";
import { FORMA_DJR_DECK, MR_EAVESXL_MODERN } from "../GlobalConstants";
import Success from "./Success";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    padding: "5%",
    paddingBottom: "7%",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "11%",
    },
  },
  formControl: {
    width: "100%",
    [theme.breakpoints.up("md")]: { marginLeft: "10%" },
  },
  text1Container: {
    justifyContent: "flex-start",
    paddingTop: "5%",
    [theme.breakpoints.up("md")]: {},
  },
  form: {
    justifyContent: "flex-start",
    [theme.breakpoints.up("md")]: {
      paddingRight: "15%",
    },
  },
  text1_1: {
    color: theme.palette.secondary.main,
    fontSize: 30,
    fontFamily: FORMA_DJR_DECK,
    [theme.breakpoints.up("md")]: {
      fontSize: 40,
    },
  },
  text1_2: {
    color: theme.palette.primary.light,
    fontSize: 14,
    fontFamily: MR_EAVESXL_MODERN,
    [theme.breakpoints.up("md")]: {
      fontSize: 25,
    },
  },
  text1_3: {
    color: theme.palette.primary.light,
    fontSize: 14,
    fontFamily: MR_EAVESXL_MODERN,
    marginTop: "4%",
    marginBottom: "3%",

    [theme.breakpoints.up("md")]: {
      fontSize: 25,
    },
  },
  input: {
    width: "80%",
    marginBottom: "3%",
    fontFamily: FORMA_DJR_DECK,
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  select: {
    width: "80%",
    marginBottom: "5%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  WhiteLabel: {
    color: theme.palette.secondary.main,
    textAlign: "left",

    marginTop: "4%",
    marginBottom: "1%",
    fontSize: 16,
    fontFamily: MR_EAVESXL_MODERN,
    [theme.breakpoints.up("md")]: {
      fontSize: 20,
      marginLeft: "10%",
    },
  },
  contactBtnContainer: {
    justifyContent: "flex-start",
    paddingTop: "3%",
    [theme.breakpoints.up("md")]: { paddingLeft: "10%" },
  },
  btnContact: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    //padding: 10,
    /* paddingLeft: 20,
    paddingRight: 20, */
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    minWidth: 150,
    fontFamily: FORMA_DJR_DECK,
    fontWeight: "bold",
    fontSize: 20,
  },
  DarkLabel: {
    fontFamily: FORMA_DJR_DECK,
    fontWeight: "bold",
    fontSize: 20,
    color: theme.palette.primary.main,
    // backgroundColor: "transparent",
  },
}));

const Form = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const [name, setName] = useState({ value: "", validity: false });
  const [email, setEmail] = useState({ value: "", validity: false });
  const [mobile, setMobile] = useState({ value: "", validity: false });

  const [help, setHelp] = useState({ value: 0, validity: false });
  const [desc, setDesc] = useState({ value: "", validity: false });
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);

  const validations = () => {
    if (!name.validity) {
      setError("Invalid name!");
      return false;
    }
    if (!email.validity) {
      setError("Invalid email address!");
      return false;
    }
    if (!help.validity || help.value === 0) {
      setError("Please choose a help category!");
      return false;
    }
    return true;
  };

  const saveFormHanlder = (e) => {
    e.preventDefault();
    if (!validations()) {
      return;
    }

    const db = Firestore.firestore();
    db.settings({
      timestampsInSnapshots: true,
    });

    db.collection("form")
      .add({
        name: name.value,
        description: desc && desc.value,
        email: email.value,
        category: help.value,
        mobile: mobile.value,
        created: Firestore.firestore.Timestamp.now(),
      })
      .then((res) => {
        setOpen(true);
      });

    document.getElementById("name").value = "";
    document.getElementById("emailId").value = "";

    document.getElementById("help").innerHTML = "";

    document.getElementById("mobile").value = "";
    document.getElementById("desc").value = "";

    setEmail();
    setDesc();
    setMobile();
    setHelp({
      value: 0,
      validity: false,
    });
    setDesc();
  };

  return (
    <Grid>
      <Success open={open} handleClose={setOpen.bind(this, false)} />

      <Grid
        xs={12}
        item
        container
        className={classes.root}
        ref={ref}
        style={{
          WebkitFilter: open && "blur(10px)",
          filter: open && "blur(10px)",
          msFilter: open && "blur(10px)",
        }}
      >
        <Error error={error} errorConfirmedHandler={setError} />
        <Grid xs={12} md={5} item className={classes.text1Container}>
          <Grid xs={12} item>
            <Typography className={classes.text1_1} align="left">
              Let's talk!
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography className={classes.text1_1} align="left">
              Tell us about your project.
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography className={classes.text1_1} align="left">
              We will be in touch.
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography className={classes.text1_3} align="left">
              Calling Hours : Mon - Sat : 9 AM to 6 PM
            </Typography>
          </Grid>
          <Grid xs={12} item>
            <Typography className={classes.text1_2} align="left">
              Contact No : +91 97162 44051
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={7} item container className={classes.text1Container}>
          <form
            // style={{ width: "100%", paddingTop: "5%" }}
            className={classes.form}
            onSubmit={saveFormHanlder}
          >
            <TextField
              className={classes.input}
              id="name"
              variant="outlined"
              color="primary"
              placeholder="Your Name*"
              type="text"
              onChange={(e) =>
                setName({
                  value: e.target.value,
                  validity: e.target.validity.valid,
                })
              }
              required
            />
            <TextField
              className={classes.input}
              variant="outlined"
              color="primary"
              id="emailId"
              placeholder="Your Email Address*"
              type="email"
              required
              onChange={(e) =>
                setEmail({
                  value: e.target.value,
                  validity: e.target.validity.valid,
                })
              }
            />
            <TextField
              className={classes.input}
              variant="outlined"
              color="primary"
              placeholder="Mobile Number"
              type="number"
              id="mobile"
              required
              onChange={(e) =>
                setMobile({
                  value: e.target.value,
                  validity: e.target.validity.valid,
                })
              }
            />

            <FormControl variant="outlined" className={classes.formControl}>
              {/* <InputLabel
                id="demo-simple-select-outlined-label"
                className={classes.DarkLabel}
              >
                How can we help you?
              </InputLabel> */}
              <Select
                //style={{ width: "90%" }}
                labelId="demo-simple-select-outlined-label"
                id="help"
                required
                onChange={(e) => {
                  setHelp({
                    value: e.target.value,
                    validity: true,
                  });
                }}
                className={classes.select}
                variant="outlined"
                value={help.value}
              >
                <MenuItem value={0} disabled>
                  {"How can we help you?"}
                </MenuItem>
                <MenuItem
                  value={`Complete Digital Marketing Solution`}
                >{`Complete Digital Marketing Solution`}</MenuItem>
                <MenuItem
                  value={`Generate Leads or Sales via Your Website`}
                >{`Generate Leads or Sales via Your Website`}</MenuItem>
                <MenuItem
                  value={`Brand Awareness & Improve Share of Voice`}
                >{`Brand Awareness & Improve Share of Voice`}</MenuItem>
                <MenuItem
                  value={`Search Engine Optization(SEO)`}
                >{`Search Engine Optization(SEO)`}</MenuItem>
                <MenuItem
                  value={`Creative Social Campaigns & Content (SMM / SMO)`}
                >{`Creative Social Campaigns & Content (SMM / SMO)`}</MenuItem>
                <MenuItem
                  value={`Social Ads (Fb, Insta, Likedin, Quora)`}
                >{`Social Ads (Fb, Insta, Likedin, Quora)`}</MenuItem>
                <MenuItem
                  value={`Google Ads (SEM / PPC)`}
                >{`Google Ads (SEM / PPC)`}</MenuItem>
                <MenuItem
                  value={`Google Design & Videos`}
                >{`Google Design & Videos`}</MenuItem>
                <MenuItem
                  value={`Graphic Design & Videos`}
                >{`Graphic Design & Videos`}</MenuItem>
                <MenuItem
                  value={`Website Development`}
                >{`Website Development`}</MenuItem>
                <MenuItem
                  value={`Job / Internship`}
                >{`Job / Internship`}</MenuItem>
                <MenuItem value={`Other!`}>{`Other!`}</MenuItem>
              </Select>
            </FormControl>
            <Typography className={classes.WhiteLabel}>
              Got something more to say?
            </Typography>
            <TextField
              id="desc"
              placeholder="Type your message here…"
              className={classes.input}
              multiline
              rows={5}
              variant="outlined"
              onChange={(e) =>
                setDesc({
                  value: e.target.value,
                  validity: e.target.validity.valid,
                })
              }
            />
            <Grid
              item
              container
              xs={12}
              className={classes.contactBtnContainer}
            >
              <Button className={classes.btnContact} type="submit">
                Send
              </Button>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default Form;
