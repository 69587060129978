import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import mmm from "../assets/mmm.png";

import mtr from "../assets/mtr.png";
import angel from "../assets/angel.png";
import bajaj from "../assets/bajaj.png";
import lybra from "../assets/lybra.png";
import royal from "../assets/royal.png";
import exide from "../assets/exide.png";
import icici from "../assets/icici.png";
import sbi from "../assets/sbi.png";
import vishal from "../assets/visha.png";
import spartan from "../assets/spartan.png";
import oxygen from "../assets/oxygen.png";
import city from "../assets/city.png";
import bonomo from "../assets/bonomo.png";
import dream11 from "../assets/dream11.png";
import niit from "../assets/niit.png";

import { FORMA_DJR_DECK } from "../GlobalConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    width: "100%",
    padding: "5%",
    paddingTop: "10%",
    paddingLeft: "10%",
    paddingRight: "1%",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "11%",
    },
  },
  text1Container: {
    justifyItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "2%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "80%",
    },
  },
  text1_1: {
    color: theme.palette.primary.main,
    fontSize: 30,
    fontFamily: FORMA_DJR_DECK,
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: 50,
    },
  },
  itemsContainer: {
    paddingTop: "10%",
    [theme.breakpoints.up("md")]: {
      paddingBottom: "5%",
      paddingRight: "10%",
      paddingTop: 0,
      maxWidth: "100%",
    },
  },
  item1: {
    borderRadius: "60%",
    borderWidth: 2,
    maxWidth: 70,
    height: 70,
    boxShadow: " 2px 2px 20px #e6e6e6",
    marginBottom: "30%",
    marginRight: "4%",
    alignContent: "center",
    overflow: "hidden",
    //marginBottom: "18%",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      maxWidth: 125,
      height: 125,
      marginBottom: "30%",
      marginRight: "4%",
    },
  },
  spacing: {
    paddingBottom: "8%",
  },
  logo: {
    height: 60,
    width: 60,
    // alignSelf: "center",
    [theme.breakpoints.up("md")]: {
      height: 125,
      width: 125,
    },
  },
}));
const Clients = (props) => {
  const classes = useStyles();

  return (
    <Grid xs={12} item container className={classes.root}>
      <Grid xs={12} md={3} item container className={classes.text1Container}>
        <Typography className={classes.text1_1} align="left">
          Few of our clients.
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={9}
        item
        //spacing={3}
        container
        className={classes.itemsContainer}
      >
        <Grid xs={3} item>
          <Grid
            xs={12}
            item
            className={classes.item1}
            container
            justify="center"
          >
            <img className={classes.logo} alt="logo" src={niit} />
          </Grid>
        </Grid>
        <Grid xs={3} item>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={icici} />
          </Grid>
        </Grid>
        <Grid xs={3} item>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={sbi} />
          </Grid>
        </Grid>
        <Grid xs={3} item container>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={dream11} />
          </Grid>
        </Grid>
        <Grid xs={3} item container>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={city} />
          </Grid>
        </Grid>
        <Grid xs={3} item container>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={vishal} />
          </Grid>
        </Grid>
        <Grid xs={3} item container>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={angel} />
          </Grid>
        </Grid>
        <Grid xs={3} item container>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={bajaj} />
          </Grid>
        </Grid>
        <Grid xs={3} item container>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={mtr} />
          </Grid>
        </Grid>
        <Grid xs={3} item container>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={royal} />
          </Grid>
        </Grid>
        <Grid xs={3} item container>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={bonomo} />
          </Grid>
        </Grid>
        <Grid xs={3} item container>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={exide} />
          </Grid>
        </Grid>
        <Grid xs={3} item container>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={oxygen} />
          </Grid>
        </Grid>
        <Grid xs={3} item container>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={spartan} />
          </Grid>
        </Grid>
        <Grid xs={3} item container>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={lybra} />
          </Grid>
        </Grid>
        <Grid xs={3} item container>
          <Grid
            xs={12}
            item
            className={classes.item1}
            justify="center"
            container
          >
            <img className={classes.logo} alt="logo" src={mmm} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Clients;
