import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
// import whatsApp from "../assets/Group 7564.svg";
// import youtube from "../assets/Group 7566.svg";

// import twitter from "../assets/Group 7573.svg";
import fb from "../assets/Group 7576.svg";
import insta from "../assets/Group 7577.svg";
import lindn from "../assets/Group 7586.svg";
import { FORMA_DJR_DECK } from "../GlobalConstants";
//import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    //backgroundColor: theme.palette.secondary.main,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      //paddingLeft: "10%",
    },
  },
  grid1: {
    paddingLeft: "7%",
    paddingRight: "4%",
    paddingTop: "2%",
    paddingBottom: "2%",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
      paddingTop: "8%",
      paddingBottom: "8%",
    },
  },
  grid2: {
    paddingLeft: "4%",
    paddingRight: "4%",
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  logo: {
    paddingRight: "5%",
    alignSelf: "center",
    cursor: "pointer",
  },
  text: {
    fontFamily: FORMA_DJR_DECK,
    color: theme.palette.primary.main,
    fontSize: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
      marginBottom: "5%",
      marginTop: "5%",
    },
  },
}));
const Footer = (props) => {
  const classes = useStyles();

  const currentYear = function getCurrentYear() {
    const date = new Date();

    return date.getFullYear();
  };

  return (
    <Grid xs={12} item container className={classes.root}>
      <Grid
        md={6}
        xs={12}
        item
        container
        justify="center"
        className={classes.grid1}
      >
        <a
          target="_blank"
          href="https://www.facebook.com/ADYLLdigital"
          rel="noopener noreferrer"
          className={classes.logo}
        >
          <img src={fb} alt="media" />
        </a>

        <a href="/" target="_blank" className={classes.logo}>
          <img src={insta} alt="media" />
        </a>

        <a
          target="_blank"
          href="https://www.linkedin.com/company/adyll"
          className={classes.logo}
          rel="noopener noreferrer"
        >
          <img src={lindn} alt="media" />
        </a>
      </Grid>
      <Grid
        md={6}
        xs={12}
        item
        container
        justify="center"
        alignContent="center"
        className={classes.grid2}
      >
        <Typography
          className={classes.text}
        >{`©${currentYear()} Adyll. All rights reserved.`}</Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
