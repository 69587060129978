import React from "react";
import {
  makeStyles,
  Modal,
  Backdrop,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import { FORMA_DJR_DECK } from "../GlobalConstants";
import image from "../assets/thankyou_pop.png";

const useStyles = makeStyles((theme) => ({
  root: {
    background: " rgba(0, 0, 0, 0.5)",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",

    //background: "#fff",

    //backgroundColor: theme.palette.text.secondary,
  },
  paper: {
    backgroundColor: theme.palette.text.primary,
    width: "80%",
    marginTop: "6%",

    [theme.breakpoints.up("md")]: {
      width: "43%",
      marginTop: "6%",
    },

    outline: 0,
    borderRadius: 20,
    overflow: "hidden",
    borderWidth: 0,
    paddingBottom: "1%",
    paddingTop: "1%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "6%",
      paddingRight: "6%",
      paddingBottom: "5%",
    },
  },
  thankU: {
    fontSize: 50,
    color: theme.palette.primary.main,
    fontFamily: FORMA_DJR_DECK,
    marginBottom: "2%",
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      fontSize: 46,
    },
  },
  inTouch: {
    fontSize: 25,
    color: theme.palette.primary.main,
    fontFamily: FORMA_DJR_DECK,
    marginBottom: "6%",
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
      maxWidth: "85%",
    },
  },
  getBack: {
    fontSize: 25,
    color: theme.palette.primary.light,
    fontFamily: FORMA_DJR_DECK,
    marginBottom: "1%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "85%",
    },
  },
  btn: {
    height: 40,
    width: 130,
    marginTop: "5%",
    fontSize: 20,
    fontFamily: FORMA_DJR_DECK,
    marginBottom: "8%",
  },
  wrapper: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      paddingLeft: "5%",
      paddingRight: "5%",
    },
  },
  wrapper1: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  wrapper2: {
    paddingLeft: "5%",
    paddingRight: "5%",
    justifyContent: "flex-start",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  wrapperImg: {
    marginTop: "1%",
    marginBottom: "5%",
  },
}));

const Success = (props) => {
  const classes = useStyles();

  return (
    <Grid container justify="center" className={classes.root}>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Grid container justify="center" className={classes.paper}>
          <Grid
            xs={12}
            item
            container
            justify="center"
            className={classes.wrapperImg}
          >
            <img alt="logo" src={image} />
          </Grid>
          <Grid
            xs={12}
            item
            container
            justify="center"
            className={classes.wrapper}
          >
            <Typography className={classes.thankU}>Thank You!</Typography>
          </Grid>
          <Grid
            xs={12}
            item
            container
            justify="center"
            className={classes.wrapper}
          >
            <Typography className={classes.inTouch}>
              For getting in touch with us.
            </Typography>
          </Grid>
          <Grid
            xs={12}
            item
            container
            justify="center"
            className={classes.wrapper1}
          >
            <Typography className={classes.getBack}>
              We will get back to you shortly
            </Typography>
          </Grid>

          <Grid
            xs={12}
            item
            container
            justify="center"
            className={classes.wrapper1}
          >
            <Typography className={classes.getBack}>
              through mail or call.
            </Typography>
          </Grid>
          <Grid
            xs={12}
            item
            container
            justify="center"
            className={classes.wrapper2}
          >
            <Typography className={classes.getBack}>
              We will get back to you shortly through Mail or Call.
            </Typography>
          </Grid>
          <Grid
            xs={12}
            item
            container
            justify="center"
            className={classes.wrapper}
          >
            <Button className={classes.btn} onClick={props.handleClose}>
              Dismiss
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default Success;
