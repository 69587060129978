import React, { createRef } from "react";
import "./App.css";
import Header from "./Components/Header";

import scrollToComponent from "react-scroll-to-component";
import Services from "./Components/Services";
import Footer from "./Components/Footer";
import TermsAndConditions from "./Components/TermsAndConditions";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./Components/Home";

function App() {
  //const purposeRef = createRef();
  const servicesRef = createRef();
  const contactRef = createRef();
  const appHome = createRef();

  // const focusOnPurpose = () => {
  //   console.log(purposeRef.current);

  //   scrollToComponent(purposeRef.current, {
  //     offset: 0,
  //     align: "top",
  //     duration: 1000,
  //   });
  // };

  const focusOnServices = () => {
    console.log(servicesRef.current);

    scrollToComponent(servicesRef.current, {
      offset: 0,
      align: "top",
      duration: 1000,
    });
  };

  const focusOnContact = () => {
    scrollToComponent(contactRef.current, {
      offset: 0,
      align: "top",
      duration: 1000,
    });
  };

  const focusOnHome = () => {
    scrollToComponent(appHome.current, {
      offset: 0,
      align: "top",
      duration: 1000,
    });
  };
  return (
    <Router>
      <div className="App">
        <Header
          focusOnServices={focusOnServices}
          focusOnContact={focusOnContact}
          focusOnHome={focusOnHome}
        />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/terms-and-conditions" component={TermsAndConditions} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
        </Switch>
        <Services
          focusOnServices={focusOnServices}
          focusOnContact={focusOnContact}
        />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
